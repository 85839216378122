<template>
  <th-wrapper :title="$t('common.headers.reasons.title')">
    <template #subtitle>
      <span v-text="$t('pages.settings.pos.financials.reasons.subtitle')" />
      <router-link :to="{ name: 'reasons-list' }">
        <span v-text="$t('pages.settings.pos.financials.reasons.link')" />
      </router-link>
    </template>
    <div class="flex items-center mb-4">
      <!-- Expense Reason -->
      <el-switch
        v-model="expenseReason"
        :active-text="
          $t('pages.settings.financials.reasons.expense_reason_required.text')
        "
      />
    </div>
    <div class="flex items-center">
      <!-- Deposit Reason -->
      <el-switch
        v-model="depositReason"
        :active-text="
          $t('pages.settings.financials.reasons.deposit_reason_required.text')
        "
      />
    </div>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    expenseReason: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'financials.expense_reason_required'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'financials.expense_reason_required',
          value: newValue || false
        })
      }
    },
    depositReason: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'financials.deposit_reason_required'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'financials.deposit_reason_required',
          value: newValue || false
        })
      }
    }
  }
}
</script>

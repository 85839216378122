<template>
  <th-wrapper
    :title="$t('pages.settings.pos.financials.notes.title')"
    :subtitle="$t('pages.settings.pos.financials.notes.subtitle')"
  >
    <div class="flex items-center mb-4">
      <!-- Expense Note-->
      <el-switch
        v-model="expenseNote"
        :active-text="
          $t('pages.settings.financials.notes.expense_note_required.text')
        "
      />
    </div>
    <div class="flex items-center mb-4">
      <!-- Deposit Note -->
      <el-switch
        v-model="depositNote"
        :active-text="
          $t('pages.settings.financials.notes.deposit_note_required.text')
        "
      />
    </div>
    <div class="flex items-center mb-4">
      <!-- Bank Note -->
      <el-switch
        v-model="bankNote"
        :active-text="
          $t('pages.settings.financials.notes.bank_note_required.text')
        "
      />
    </div>
    <div class="flex items-center mb-4">
      <!-- Safe Note -->
      <el-switch
        v-model="safeNote"
        :active-text="
          $t('pages.settings.financials.notes.safe_note_required.text')
        "
      />
    </div>
    <div class="flex items-center mb-4">
      <!-- Price Change Note -->
      <el-switch
        v-model="priceChangeNote"
        :active-text="
          $t('pages.settings.financials.notes.price_change_note_required.text')
        "
      />
    </div>
    <div class="flex items-center">
      <!-- Tips Expense Note -->
      <el-switch
        v-model="tipsExpenseNote"
        :active-text="
          $t('pages.settings.financials.notes.tips_expense_note_required.text')
        "
      />
    </div>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    expenseNote: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'financials.expense_note_required'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'financials.expense_note_required',
          value: newValue || false
        })
      }
    },
    depositNote: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'financials.deposit_note_required'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'financials.deposit_note_required',
          value: newValue || false
        })
      }
    },
    bankNote: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'financials.bank_note_required'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'financials.bank_note_required',
          value: newValue || false
        })
      }
    },
    safeNote: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'financials.safe_note_required'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'financials.safe_note_required',
          value: newValue || false
        })
      }
    },
    priceChangeNote: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'financials.price_change_note_required'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'financials.price_change_note_required',
          value: newValue || false
        })
      }
    },
    tipsExpenseNote: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'financials.tips_expense_note_required'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'financials.tips_expense_note_required',
          value: newValue || false
        })
      }
    }
  }
}
</script>

<template>
  <section>
    <notes />
    <reasons />
    <low-cash-threshold />
  </section>
</template>

<script>
import Notes from './components/notes'
import Reasons from './components/reasons'
import LowCashThreshold from './components/low-cash-threshold'

export default {
  components: {
    Notes,
    Reasons,
    LowCashThreshold
  }
}
</script>

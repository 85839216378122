<template>
  <th-wrapper
    :title="$t('pages.settings.pos.financials.low_cash_threshold.title')"
    :subtitle="$t('pages.settings.pos.financials.low_cash_threshold.subtitle')"
  >
    <!-- Low Cash Threshold-->
    <multi-amount-currency-input
      category-name="financials"
      field="low_cash_threshold"
    />
  </th-wrapper>
</template>

<script>
import MultiAmountCurrencyInput from '../../components/multi-amount-currency-input'

export default {
  components: {
    MultiAmountCurrencyInput
  }
}
</script>
